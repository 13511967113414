/* globals.css */
p {
  margin: 0;
  padding: 0;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}

body {
  background: #f5f5f5;
}

.service .ant-card-actions > li:first-child {
  width: 75% !important;
  padding-left: 24px;
}

.custom-progress-track {
  border-radius: 9.5px;
  overflow: hidden;
  background: #e3e3e3;
  box-shadow: 0px 4px 4px 0px rgba(202, 202, 202, 0.25) inset;
}

.loading-image {
  width: 100%;
  /* max-height: 72px; */
  object-fit: contain;
  opacity: 1; /* Initially set to fully visible */
  transition: opacity 0s ease-in-out; /* Smooth transition over 0.5 seconds */
}

.custom-progress-track .progress-fill {
  border-radius: 0;
  box-shadow: 0px 4px 4px 0px rgba(202, 202, 202, 0.25) inset;
}

.custom-progress-track .progress-fill:first-child {
  border-bottom-left-radius: 9.5px;
  border-top-left-radius: 9.5px;
}

.custom-progress-track .progress-fill:last-child {
  border-bottom-right-radius: 9.5px;
  border-top-right-radius: 9.5px;
}

.tracking-card .ant-card-body {
  padding: 20px 12px 10px 12px;
}
.marquee {
  width: 100%;
  overflow: hidden;
}

.marquee-inner {
  white-space: nowrap;
  display: inline-block;
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media only screen and (min-width: 1024px) {
  .ant-menu-item-selected::before {
    width: 4px;
    position: absolute;
    top: 0.65rem;
    bottom: 0.65rem;
    left: 0px;
    background: #ff743d;
    border-radius: 0 4px 4px 0;
    content: "";
  }
}

.ant-menu-horizontal .ant-menu-item {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ant-picker .ant-picker-input span.ant-picker-suffix,
.ant-picker.ant-picker-range span.ant-picker-suffix {
  margin-left: 5px !important;
  margin-right: 20px !important;
  order: -1 !important;
}

.anticon-star {
  font-size: 2.5rem !important;
  /* color: ##ffd700 !important; */
}
.ant-rate-star-full,
.ant-rate-star-half {
  color: #ffd700 !important;
}

.billingCollapse .ant-collapse-header {
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0 !important;
  padding-bottom: 20px !important;
}

.billingCollapse .ant-collapse-content-box {
  padding-block: 0px !important;
}
