/* /* fonts.css */
@font-face {
  font-family: "Ambit";
  font-weight: 100;
  font-style: normal;
  src: url("../fonts/Ambit-Thin.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 200;
  font-style: normal;
  src: url("../fonts/Ambit-ExtraLight.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 300;
  font-style: normal;
  src: url("../fonts/Ambit-Light.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 400;
  font-style: normal;
  src: url("../fonts/Ambit-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-style: normal;
  src: url("../fonts/Ambit-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 500;
  font-style: normal;
  src: url("../fonts/Ambit-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 600;
  font-style: normal;
  src: url("../fonts/Ambit-SemiBold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Ambit";
  font-weight: 700;
  font-style: normal;
  src: url("../fonts/Ambit-Bold.ttf") format("truetype");
  font-display: swap;
}

/* @font-face {
  font-family: "Ambit";
  font-weight: 800;
  font-style: normal;
  src: url("../fonts/Ambit-ExtraBold.ttf") format("truetype");
  font-display: swap;
} */

@font-face {
  font-family: "Ambit";
  font-weight: 900;
  font-style: normal;
  src: url("../fonts/Ambit-Black.ttf") format("truetype");
  font-display: swap;
}
/* @font-face {
      font-family: "Ambit";
      font-weight: 100;
      font-style: normal;
      src: url("../fonts/Ambit-BlackItalic.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 200;
      font-style: normal;
      src: url("../fonts/Ambit-Bold.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 200;
      font-style: normal;
      src: url("../fonts/Ambit-BoldItalic.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 300;
      font-style: normal;
      src: url("../fonts/Ambit-ExtraLight.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 300;
      font-style: normal;
      src: url("../fonts/Ambit-ExtraLight.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 400;
      font-style: normal;
      src: url("../fonts/Ambit-Regular.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 500;
      font-style: normal;
      src: url("../fonts/Ambit-SemiBold.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 500;
      font-style: bold;
      src: url("../fonts/Ambit-SemiBoldItalic.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 600;
      font-style: normal;
      src: url("../fonts/Ambit-Thin.ttf") format("truetype");
      font-display: swap;
    }
    
    @font-face {
      font-family: "Ambit";
      font-weight: 600;
      font-style: normal;
      src: url("../fonts/Ambit-ThinItalic.ttf") format("truetype");
      font-display: swap;
    } */
