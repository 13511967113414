@import url("../../asset/css/font.css");

.bilty {
  /* font-family: "Ambit" !important; */
  font-size: 22px;
  background-color: white;
  /* word-spacing: 2px; */
}

.bilty .bilty-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bilty .bilty-address {
  width: 500px;
}

.bilty .bilty-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.bilty .bilty-title {
  font-size: 72px;
  font-weight: 600;
}

.bilty .bilty-sub-line {
  border-bottom: 6px solid #f26a34;
  width: 830px;
}

.bilty .bilty-details-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 60px;
}

.bilty .bilty-horizontal-line {
  border-right: 3px solid #f9b59a;
  height: 73px;
}

.bilty span {
  font-size: 19px;
  font-weight: 400;
  color: #828282;
}

.bilty .bilty-from-details {
  margin-top: 40px;
}

.bilty-mb-20 {
  margin-bottom: 20px;
}

.table-container {
  width: 100%;
  /* max-width: 1000px; */
  margin: auto;
  overflow-x: auto;
  margin: 40px 0 80px 0;
  margin-bottom: 80px;
  border: 2px solid #f36f21;
  border-radius: 10px;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;

  font-size: 1em;
  min-width: 400px;

  /* overflow: hidden; */
}

.styled-table thead tr {
  background-color: #f36f21;
  color: #ffffff;
  text-align: left;
}

.styled-table th,
.styled-table td {
  padding: 12px 15px;
}

.styled-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:last-of-type {
  border-bottom: none;
}

.styled-table tbody tr.active-row {
  font-weight: bold;
  color: #f36f21;
}

/* body {
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f4;
} */

.font-size-28 {
  font-size: 28px !important;
}

.invoice {
  /* width: 1240px;
  height: 1754px; */
  background: #fff;
  /* padding: 40px; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.15); */
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 70px;
}

.invoice-logo {
  /* max-width: 200px; */
}

.invoice-address {
  /* text-align: right; */
  width: 500px;
}
.invoice-address p {
  margin: 5px 0;
}

.invoice h1 {
  font-size: 2.5em;
  color: #333;
  border-bottom: 2px solid #f36f21;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.invoice-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.info-block {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-right: 3px solid #f9b59a;
}

.info-block:last-child {
  border-right: none;
}

.invoice-to {
  margin-bottom: 20px;
}

.invoice-to p {
  margin: 10px 0;
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 1em;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  border: 2px solid #f36f21;
  border-radius: 10px;
  overflow: hidden;
}

.invoice-table thead tr {
  background-color: #f36f21;
  color: #ffffff;
}

.invoice-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.invoice-table th,
.invoice-table td {
  padding: 12px 15px;
  /* border: 1px solid #dddddd; */
}

.invoice-summary {
  width: 400px;
  border: 1px solid #cbd5e1;
  border-radius: 20px;
  padding: 20px;
}

.invoice-summary table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dddddd;
  border-radius: 10px;
}

.invoice-summary td {
  padding: 8px 10px;
  /* border: 1px solid #dddddd; */
  text-align: right;
}

.invoice-summary td:first-child {
  text-align: left;
}

.invoice-summary .verticle-line {
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin-bottom: 12px;
}

.invoice-summary .list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
.invoice-summary .list p {
  font-size: 20px;
}

.primary-color {
  color: #f26a34;
}

footer {
  margin-top: 180px;
  text-align: center;
  font-size: 0.8em;
  color: #555;
}

footer a {
  color: #f36f21;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}
